import type { ActionFunction, LoaderFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { useActionData } from '@remix-run/react';

import { AuthorizationError } from 'remix-auth';
import { validationError } from 'remix-validated-form';

import { authenticator } from '~/services/auth.server';
import { returnToCookie } from '~/services/session.server';
import loginHeroAnimations from '~/components/Signup/styles.css';
import { generateMeta } from '~/utils/generateMeta';

import LoginView from './View';

export function links() {
  return [
  {
    rel: 'stylesheet',
    href: loginHeroAnimations
  }];

}

type LoaderData = {
  cameFromMainRaffleSite: boolean;
  referer: string | null;
};
export const loader: LoaderFunction = async ({ request }) => {
  const cameFromMainRaffleSite =
  request.headers.get('referer')?.includes('https://raffle.ai') || false;

  await authenticator.isAuthenticated(request, {
    successRedirect: '/insights'
  });

  const url = new URL(request.url);
  const returnTo = url.searchParams.get('return_to');

  const headers = new Headers();
  if (returnTo) {
    headers.append('Set-Cookie', await returnToCookie.serialize(returnTo));
  }

  return json<LoaderData>(
    {
      cameFromMainRaffleSite,
      referer: request.headers.get('referer')
    },
    { headers }
  );
};

export const meta = generateMeta({
  title: 'Login',
  extraMetaTags: [
  { name: 'description', content: 'Login to use the administration tools' }]

});

export type ActionData = {
  validationErrors: {
    fieldErrors: {
      email: string;
      password: string;
      allow_submit: boolean;
    };
    repopulateFields: {
      email: string;
      password: string;
    };
  };
};
export const action: ActionFunction = async ({ request }) => {
  const returnTo =
  (await returnToCookie.parse(request.headers.get('Cookie'))) ?? '/';

  const copyRequest = request.clone();
  const formData = await copyRequest.formData();
  const email = formData.get('email') as string;
  const password = formData.get('password') as string;

  try {
    return await authenticator.authenticate('raffle-auth', request, {
      successRedirect: returnTo ?? '/',
      throwOnError: true
    });
  } catch (error) {
    // the actual redirect happens here! (the success response is also thrown)
    if (error instanceof Response) return error;
    if (error instanceof AuthorizationError) {
      return validationError(
        {
          fieldErrors: {
            email: 'Incorrect email or password',
            password: 'Incorrect email or password',
            allow_submit: 'true'
          }
        },
        {
          email,
          password
        }
      );
    }

    if (error instanceof Response && isRedirect(error)) {
      error.headers.append(
        'Set-Cookie',
        await returnToCookie.serialize(returnTo)
      );
      throw error;
    }

    throw error;
  }
};

function isRedirect(response: Response) {
  if (response.status < 300 || response.status >= 400) return false;
  return response.headers.has('Location');
}

export default function Login() {
  const actionData = useActionData<ActionData>();

  return <LoginView actionData={actionData?.validationErrors} />;
}